.suggest-input-container {
    position: relative;
}

.suggest-list {
    position: absolute;
    z-index: 10;
    max-height: 200px;
    width: 100%;
    top: 43px;
    // padding: 5px;
    background-color: #fff;
    border: 2px solid #edeff3;
    border-radius: 8px;
    overflow: auto;

    & li {
        padding: 2px 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;

        &:hover {
            background-color: #e9ecef;
            cursor: pointer;
        }

        &.active {
            background-color: #e9ecef;
        }

        & span {
            display: inline-block;
            padding: 0 10px;
        }
    }
}

.highlighted {
    background-color: #e9ecef;
}
