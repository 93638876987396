.toggle-button {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.toggle-button-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.toggle-button-check,
.toggle-button-uncheck {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #001a37;
    transition: opacity 0.3s ease;

    @media screen and (max-width: 639px) {
        font-size: 14px;
    }
}

.toggle-button-check {
    opacity: 0.54;
    font-weight: 600;
}

.toggle-button-uncheck {
    opacity: 1;
    font-weight: 600;
}

.toggle-button-container {
    width: 44px;
    height: 24px;
    padding: 0;
    border-radius: 12px;
    background-color: $primary;
    transition: all 0.2s ease;
}

.toggle-button-circle {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fafafa;
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.toggle-button--checked .toggle-button-check {
    opacity: 1;
}
.toggle-button--checked .toggle-button-uncheck {
    opacity: 0.54;
}
.toggle-button--checked .toggle-button-circle {
    left: 22px;
}

.toggle-disabled-container .toggle-button-container {
    background-color: #d3d4d5;
}

.toggle-small-size .toggle-button-container {
    width: 36px;
    height: 19px;
}

.toggle-small-size .toggle-button-circle {
    width: 15px;
    height: 15px;
}

.toggle-small-size .toggle-button--checked .toggle-button-circle {
    left: 19px;
}
