$primary: #0d6efd !default;
$secondary: #6c757d !default;
$success: #198754 !default;
$info: #0dcaf0 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #212529 !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$black-50: #00000080 !default;

$color-grey: #f6f7f9;
$color-grey-hover: #edeff3;

$color-primary-border: #248eff;
$color-primary-active: #1486ff;
$color-primary-focus: #2990ff;
$color-primary-light: #52a5ff;

$color-yellow: #ffee32;
$color-red: #ff0000;

$color-blueblack: #002349;
$color-shadow: #42526e;
$color-landing: #001a37;
$color-landing-blue: #007bff;

// Body
$body-font: 'Gilroy';
//$body-font: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$body-bg: $white;
$body-color: $black;
$body-font-weight: 400;
$body-font-size: 16px;
$body-line-height: 1.5;
$default-border-color: $color-grey;
$header-box-shadow: 0px 4px 10px rgba($color-shadow, 0.08);

// Sidebar
$sidebar-bg: $gray-100;
$sidebar-link-border-radius: 6px;
$sidebar-link-padding: 8px 8px 8px 11px;
$sidebar-link-color: $black;
$sidebar-link-font-size: 14px;
$sidebar-link-font-weight: 500;
$sidebar-link-line-height: 18px;
$sidebar-link-hover-color: $black;
$sidebar-link-hover-bg: $gray-200;
$sidebar-route-link-padding: 4px 4px 4px 11px;
$sidebar-route-link-sub-color: $black-50;
$sidebar-route-link-border-radius: 12px;
$sidebar-btn-action-bg: #f6f7f9;
$sidebar-btn-action-hover-bg: $gray-200;

// Inputs
$input-padding-y: 0.5rem;
$input-padding-x: 0.8rem;
$input-border-color: #edeff3;
$input-border-width: 2px;
$input-border-radius: 6px;
$input-font-family: $body-font;
$input-font-size: $body-font-size;
$input-font-weight: $body-font-weight;
$input-line-height: 1.5;
$input-bg: $body-bg;
$input-color: $body-color;
$input-focus-border-color: #86b7fe;
$input-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
$input-placeholder-color: $gray-500;

// Buttons
$btn-padding-y: 0.375rem;
$btn-padding-x: 0.75rem;
$btn-font-family: inherit;
$btn-font-size: 16px;
$btn-line-height: 1.5;
$btn-border-width: 1px;
$btn-border-radius: 6px;
$btn-action-bg: $color-grey-hover;
$btn-action-default-hover-bg: rgba(13, 110, 253, 0.08) !important;
$btn-action-remove-hover-bg: #fedbe2 !important;

// Selects
$control-border: 2px solid #edeff3;
$control-border-radius: 6px;

// Checkboxes
$form-check-input-border: 1px solid $gray-300;
$form-check-input-border-radius: 0.25em;
$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: $primary;
$form-check-input-checked-border-color: $primary;
$form-check-input-focus-border: $primary;
$form-check-input-focus-box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);

// Tables
$table-cell-padding-y: 0.5rem;
$table-cell-padding-x: 0.5rem;
$table-color: $black;
$table-bg: $white;
$table-hover-bg: rgba($black, 0.075);
$table-hover-color: $black;
$table-head-border: 2px solid #edeff3 !important;

// Dropdowns
$dropdown-color: $body-color;
$dropdown-bg: $body-bg;
$dropdown-border-color: rgba(0, 0, 0, 0.175);
$dropdown-border-radius: 6px;
$dropdown-border-width: 1px;
$dropdown-divider-bg: $dropdown-border-color;
$custom-dropdown-box-shadow: 1px 4px 12px rgba($color-shadow, 0.08);
$dropdown-link-color: $black;
$dropdown-link-hover-color: $black;
$dropdown-link-hover-bg: $color-grey !important;
$dropdown-link-active-bg: $color-grey-hover !important;

// Links
$link-underline-color: $primary;
$link-underline-td-color: $primary;
$link-underline-hover-color: #0a58ca;
$link-underline-td-hover-color: #0a58ca !important;
