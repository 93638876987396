.modal-overlay {
    background-color: rgba($color-blueblack, 0.2);
}
.modal-dialog {
    &:not(.modal-dialog-centered) {
        margin-top: 200px !important;

        @media (max-height: 810px) {
            margin-top: 100px !important;
        }
    }

    &--wide {
        max-width: 1000px;
    }

    &--heightened {
        margin-top: 100px !important;
    }
    &--max-height-550 {
        max-height: 550px;
    }
}
.modal {
    z-index: 10000;
}

.min-width-600 {
    min-width: 600px;
}

.modal-dialog-body-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
