.api-nav-panell {
    display: flex;
    box-shadow: $header-box-shadow;
    padding-left: 1rem;
    padding-right: 1rem;
    position: fixed;
    top: calc(var(--topNavigationHeight, 0px) + var(--topMessageHeight, 0px));
    left: 0;
    right: 0;
    z-index: 9998;
    background-color: $body-bg;
    @media (min-width: 576px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
