.left-sidebar {
    position: fixed;
    top: calc(var(--contentTop, 0px) + var(--topMessageHeight, 0px));
    left: var(--leftPanelWidth, 0px);
    bottom: 0;
    width: var(--leftSidebarWidth, 0px);
    //transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    background-color: $sidebar-bg;
}

.sidebar-container {
    position: relative;
    width: var(--leftSidebarWidth, 0px);
    //transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    z-index: 1;
    margin-left: 0px;
    .nav-link:focus {
        color: inherit;
    }
}

.sidebar {
    visibility: visible;
    transition: none 0s ease 0s;
    opacity: 1;
    overflow: hidden auto;
    height: 100%;
}

.sidebar-content {
    height: 100%;
    min-width: 240px;
}

.sidebar-dragger {
    width: 5px;
    height: 100%;
    min-height: calc(100vh - 66px);
    cursor: ew-resize;
    padding: 4px 0 0;
    border-top: 1px solid #f8f9fa;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #f4f7f9;
    transition: transform 0.3s ease;
}

.sidebar-header {
    padding: 16px 16px 24px;
}

.sidebar-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $sidebar-link-padding;
    //min-height: 48px;
    border-radius: $sidebar-link-border-radius;
    cursor: pointer;
    font-size: $sidebar-link-font-size;
    line-height: $sidebar-link-line-height;
    //font-weight: $sidebar-link-font-weight;
    font-weight: normal;
    color: $sidebar-link-color;
    &-route {
        padding: $sidebar-route-link-padding;
        border-radius: $sidebar-route-link-border-radius;
        //min-height: 40px;
        &-subtitle {
            color: $sidebar-route-link-sub-color;
        }
    }
    .btn-action.remove,
    .btn-action.edit {
        display: none;
        min-width: 24px;
        width: 24px;
        height: 24px;
    }

    &:hover {
        color: $black;
        //background-color: $sidebar-link-hover-bg;
        .btn-action {
            background-color: $sidebar-btn-action-bg;
            &.remove,
            &.edit {
                display: flex;
            }

            &.edit:hover {
                background-color: $sidebar-btn-action-hover-bg !important;
            }
        }
    }
}

.sidebar-link-icon {
    margin-right: 16px;
    color: rgba($black, 0.7);
}

.sidebar-link-active {
    background-color: $sidebar-link-hover-bg;
    .btn-action {
        background-color: $sidebar-btn-action-bg;
    }
}

.nav-children {
    margin-left: 16px;
}
