:root {
    --JsonSchemaEditor-types-modal-height: 526px;
}

.JsonSchemaEditor {
    &__outer-wrapper {
        width: 100%;
    }

    &__schema-wrapper {
        border-top: 2px solid #edeff3;
        border-bottom: 2px solid #edeff3;
    }

    &__navigation-pannel {
        margin-bottom: 10px;
    }

    &__tab-button {
        display: inline-block;
        padding: 6px 16px;

        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #001a37b3;
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            color: #007bff;
        }

        &--active {
            color: #007bff;
            background-color: #007bff0d;
        }
    }

    &__generate-button {
        @extend .JsonSchemaEditor__tab-button;

        display: inline-flex;
        align-items: center;

        cursor: pointer;

        &:hover {
            color: #001a37b3;
            background-color: #001a370d;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
                background-color: initial;
            }
        }
    }

    &__generate-tab-button {
        display: inline-block;
        padding: 6px 16px;

        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        background-color: #007bff;

        &:disabled {
            background-color: #007bff4d;
            pointer-events: none;
        }

        &:hover {
            background-color: #0b5ed7;
        }
    }

    &__cancel-tab-button {
        display: inline-block;
        padding: 6px 16px;

        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        border-radius: 8px;
        color: #007bff;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            background-color: #007bff0d;
        }
    }

    &__schema-row {
        &:hover {
            background-color: #f6f7f9;

            & .JsonSchemaEditor__schema-dynamic-button {
                opacity: 1;

                &:disabled {
                    opacity: 0.3;
                    transform: translateZ(0);
                }
            }

            & .JsonSchemaEditor__schema-row-checkbox {
                opacity: 1;
                cursor: pointer;

                &:disabled {
                    opacity: 0.3;
                    transform: translateZ(0);
                }
            }
        }
    }

    &__schema-row-aligned {
        position: relative;
        width: 100%;

        display: flex;

        list-style: none;
    }

    &__schema-row-inner-wrapper {
        display: flex;
        position: relative;

        padding: 4px;
    }

    &__schema-static-button {
        min-width: 28px;
        min-height: 28px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 4px;
        border: none;
        cursor: pointer;
        background-color: transparent;

        &:hover {
            background-color: #edeff3;
            & > svg {
                background-color: transparent;
            }

            &:disabled {
                background-color: transparent;
                cursor: not-allowed;
            }
        }

        &:disabled {
            opacity: 0.3;
        }
    }

    &__schema-dynamic-button {
        @extend .JsonSchemaEditor__schema-static-button;
        opacity: 0;

        &:hover {
            &:disabled {
                background-color: transparent;
                cursor: not-allowed;
            }
        }
        &:disabled {
            opacity: 0;
        }
    }

    &__schema-row-checkbox {
        opacity: 0;

        &:disabled {
            opacity: 0.3;
        }
    }

    &__schema-row-entries-wrapper {
        display: flex;
        overflow: hidden;
        align-items: center;
        flex-grow: 1;

        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
    }

    &__schema-row-entries {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    &__schema-row-type {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__schema-row-input {
        padding: 0;

        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        background-color: transparent;
        border: none;
        outline: none;

        &::placeholder {
            color: rgba(0, 0, 0, 0.4);
        }
    }

    &__schema-row-fake-input {
        position: absolute;
        top: 0;
        left: 0;

        font-size: 14px;
        line-height: 16px;
        font-weight: 500;

        opacity: 0;
        z-index: -100;
        white-space: pre;
    }

    &__schema-row-children-counter {
        margin-left: 8px;

        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        color: #001a37b3;
    }

    &__schema-row-description {
        width: 100%;
        margin-left: 30px;
        padding: 5px 0px;

        color: #001a37b3;
        font-size: 13px;
        word-break: break-word;
    }

    &__schema-row-behaviour {
        color: gray;
        opacity: 0.5;
        white-space: nowrap;
        font-size: 13px;

        &-mock {
            height: 16px;
            width: 89px;
        }
    }

    &__schema-types-modal {
        position: absolute;
        width: 518px;
        height: var(--JsonSchemaEditor-types-modal-height);

        display: flex;
        flex-direction: column;
        padding: 16px;

        z-index: 9999;
        background-color: #fff;
        box-shadow:
            0px 15px 35px -5px rgba(17, 24, 38, 0.15),
            0px 5px 15px -3px rgba(0, 0, 0, 0.08);
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.2));
        border-radius: 8px;
    }

    &__schema-types-modal-inner-wrapper {
        overflow-y: auto;
        padding-right: 16px;
        margin-right: -8px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &__schema-types-modal-title {
        display: block;
        margin-bottom: 8px;

        font-size: 12px;
        line-height: 14px;
        font-weight: 600;

        color: #001a37b3;
    }

    &__schema-types-modal-radio-group {
        display: flex;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        list-style: none;

        & input[type='radio'] {
            width: 0;
            height: 0;
            opacity: 0;
            pointer-events: none;
        }
    }

    &__schema-types-modal-radio-checkbox {
        position: relative;

        display: flex;
        align-items: center;
        padding: 3px 8px;
        margin: 0;

        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #001a37;
        background-color: #fff;
        border: 1px solid #edeff3;
        border-radius: 4px;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &:hover {
            background-color: #f6f7f9;
            color: #001a37;
            & * {
                background-color: #f6f7f9;
            }
        }

        &--active {
            background-color: #00c26e;
            color: #fff;
            &:hover {
                background-color: #00c26e;
                color: #fff;
                & * {
                    background-color: #00c26e;
                }
            }
        }
    }

    &__schema-types-modal-tip {
        position: absolute;
        bottom: 166px;
        left: -12px;
        transform: rotate(90deg);

        &--right {
            position: absolute;
            bottom: -12px;
            right: 10px;
        }
    }

    &__schema-types-modal-description-counter {
        font-size: 14px;
        font-weight: 500;
        color: #001a37b3;
        text-align: right;
    }

    &__schema-types-modal-close-button {
        &-wrapper {
            position: absolute;
            top: 16px;
            right: 16px;
        }

        background-color: transparent;
        color: #001a37b3;

        &:hover {
            color: #007bff;
        }
    }

    &__schema-types-modal-extra-props {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    &__delete-button {
        @extend .JsonSchemaEditor__tab-button;

        display: inline-flex;
        align-items: center;
        padding: 11px 11px;

        color: #fc4d70;
        cursor: pointer;

        &:hover {
            color: #fc4d70;
            background-color: #fc4d700d;
        }
    }

    &__examples-wrapper {
        display: flex;

        border: 1px solid #edeff3;
        border-radius: 8px;
    }

    &__examples-buttons-wrapper {
        min-width: 25%;
        max-width: 25%;

        border-right: 1px solid #edeff3;
    }

    &__examples-buttons-list {
        height: 252px;

        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    &__examples-button-wrapper {
        &:first-child {
            margin: 5px 5px 2px;
        }

        &:not(:first-child) {
            margin: 2px 5px;
        }

        &:only-child {
            margin: 5px;
        }
    }

    &__examples-add-button {
        @extend .JsonSchemaEditor__generate-button;
        width: 100%;

        padding: 6px 16px;

        background-color: #fff;
        text-align: left;
        border-radius: 8px;

        &:hover {
            color: #001a37b3;
            background-color: #001a370d;
        }
    }

    &__examples-tab-button {
        @extend .JsonSchemaEditor__tab-button;
        width: 100%;

        text-align: left;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;

        &:hover {
            background-color: #007bff0d;
        }
    }

    &__examples-copy-button {
        @extend .JsonSchemaEditor__tab-button;

        display: inline-flex;
        align-items: center;
        padding: 11px 11px;

        &:hover {
            background-color: #edeff3;
            & > svg {
                background-color: transparent;
            }
        }
    }

    &__examples-editor-wrapper {
        width: 100%;

        padding: 8px;
    }

    &__examples-info-pannel-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    &__examples-info-pannel {
        flex-grow: 1;

        border: 1px solid #edeff3;
        border-radius: 8px;

        & > input {
            padding: 9px 16px;
            border-radius: 8px;
        }
    }

    &__examples-code-editor-wrapper {
        max-height: 214px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &__examples-json-error-message {
        display: block;
        margin-top: 8px;

        font-size: 12px;
        color: red;
    }

    &__generator-wrapper {
        position: relative;
        max-height: 400px;

        padding: 12px;

        border: 1px solid #edeff3;
        border-radius: 8px;
    }

    &__generator-top-pannel-text {
        display: inline-block;

        font-size: 16px;
        line-height: 18px;
        font-weight: 400;

        &--bold {
            font-size: 16px;
            line-height: 18px;
            font-weight: 600;
        }
    }

    &__copy-button-wrapper {
        position: absolute;
        top: 6px;
        right: 6px;
        z-index: 1;
    }

    &__schema-types-enum-list {
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
        }

        &-input {
            display: block;
            width: 100%;
            padding: 5px 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 2px solid #edeff3;
            -webkit-appearance: none;
            appearance: none;
            border-radius: 8px;
            transition:
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;

            &:focus {
                color: #212529;
                background-color: #fff;
                border-color: none;
                outline: 0;
                box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
            }
        }
    }
}

.disabled {
    & span {
        pointer-events: none;
    }
    opacity: 0.3;
}
