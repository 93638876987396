.first-section {
    &__img {
        max-height: 610px;
    }
}

.intro-section {
    //background-color: rgba($primary, 0.05);

    &__bg {
        //background: url("../../../assets/img/landing/bg/bg.svg") top center no-repeat;
        //min-height: 895px;
        width: 100%;
    }
}

.landing-page {
    position: relative;
    display: block;
    margin: 0 auto;
    overflow-x: hidden;

    &__patterns-relative {
        display: none;
        position: relative;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;

        @media screen and (min-width: 768px) {
            display: block;
        }
    }

    &__patterns-absolute {
        position: absolute;
        left: 0;
        right: 0;
        top: 8rem;
        z-index: -999;
    }

    &__patterns-left {
        position: absolute;
        left: -4rem;

        @media screen and (max-width: 1023px) {
            left: -12rem;
        }
    }

    &__patterns-right {
        position: absolute;
        right: -4rem;

        @media screen and (max-width: 1023px) {
            right: -12rem;
        }
    }
}

.section-intro {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;

    &__container {
        max-width: 1140px;
        padding-top: 72px;
        margin: 0 auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-landing;

        @media screen and (max-width: 639px) {
            padding-top: 32px;
        }
    }

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 56px;
        line-height: 52px;

        &--color {
            color: $color-landing-blue;
        }

        @media screen and (max-width: 639px) {
            font-size: 32px;
            line-height: 130%;
            text-align: center;
        }
    }

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
    }

    &__item {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        &:not(:last-child) {
            padding-right: 32px;
        }

        @media screen and (max-width: 639px) {
            font-size: 14px;
        }
    }

    &__service {
        display: flex;
        align-items: center;
        padding-top: 32px;

        &:first-child {
            padding-right: 32px;

            @media screen and (max-width: 639px) {
                padding-right: 16px;
            }
        }

        @media screen and (max-width: 639px) {
            padding-top: 24px;
        }
    }

    &__label {
        padding-left: 8px;
        opacity: 0.8;
    }

    &__info-content {
        max-width: 650px;
        margin: 0 auto;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        padding: 40px 0;

        @media screen and (max-width: 639px) {
            padding: 16px 0 32px;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    &__info-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;

        @media screen and (max-width: 639px) {
            font-size: 24px;
            line-height: 130%;
        }
    }

    &__info-sign {
        padding-left: 8px;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;

        @media screen and (max-width: 639px) {
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__info-text {
        padding-top: 8px;
        font-weight: 600;
        line-height: 130%;
        opacity: 0.8;

        @media screen and (max-width: 639px) {
            font-size: 12px;
        }
    }

    &__button {
        background-color: $color-landing-blue;
    }

    &__learn-more {
        padding-left: 32px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $color-landing-blue;

        @media screen and (max-width: 639px) {
            padding: 7px 12px;
        }
    }

    &__bg {
        width: 100%;
        padding-top: 40px;
        margin: 0 auto;
        display: block;

        @media screen and (max-width: 639px) {
            display: none;
        }
    }

    &__image {
        display: block;
        margin: 0 auto;
        object-fit: cover;
    }
}

.section-feature {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 -12px;
    color: $color-landing;

    &__container {
        max-width: 1140px;

        @media screen and (max-width: 639px) {
            padding-top: 40px;
        }
    }

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 40px;
        line-height: 130%;

        @media screen and (max-width: 639px) {
            font-size: 24px;
        }

        &--size {
            font-size: 32px;

            @media screen and (max-width: 639px) {
                font-size: 24px;
            }
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        padding: 48px 0 80px;
        margin: 0 auto;

        @media screen and (max-width: 490px) {
            display: flex;
            flex-direction: column;
        }

        @media screen and (max-width: 639px) {
            padding: 24px 0 40px;
        }
    }

    &__list {
        grid-column-start: 5;
        grid-column-end: 9;
        padding-left: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        &:first-child {
            grid-column-start: 1;
            grid-column-end: 5;
            padding-left: 11px;

            @media screen and (max-width: 490px) {
                padding-left: 0;
                padding-bottom: 12px;
            }
        }

        @media screen and (max-width: 490px) {
            padding-left: 0;
        }

        @media screen and (max-width: 639px) {
            grid-column-start: 5;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            padding-bottom: 20px;

            @media screen and (max-width: 1023px) {
                padding-bottom: 12px;
            }
        }
    }

    &__text {
        padding-left: 8px;
        font-size: 12px;

        @media screen and (max-width: 490px) {
            font-size: 14px;
        }

        @media screen and (min-width: 767px) {
            font-size: 14px;
        }

        @media screen and (min-width: 1023px) {
            font-size: 16px;
            padding-left: 16px;
        }
    }

    &__icon {
        display: block;
        width: 16px;
        height: 16px;

        @media screen and (max-width: 639px) {
            width: 14px;
            height: 14px;
        }
    }

    &__started {
        background: #edf6ff;
        border-radius: 16px;
    }

    &__started-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 64px 0;

        @media screen and (max-width: 639px) {
            padding: 32px;
        }
    }

    &__description {
        padding: 16px 0 48px;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;

        @media screen and (max-width: 639px) {
            font-size: 12px;
            text-align: center;
            padding-bottom: 24px;
        }
    }

    &__button {
        background: $color-landing-blue;
    }
}

.features {
    &-header-bg {
        background: url('../../assets/img/landing/Pattern.png') no-repeat 75%
            center;
    }

    &-cards {
        background: url('../../assets/img/landing/Pattern.png') no-repeat left
            bottom;
    }

    &__card {
        width: 300px;

        &-header {
            font-weight: 500;
            font-size: 48px;
            color: rgba($primary, 0.25);

            @media (min-width: 768px) {
                font-size: 64px;
                line-height: 75px;
            }
        }
    }
}

.go-section {
    background-color: $black;

    &__bg {
        background-image: url('../../assets/img/landing/bg/Background-2.svg');
        background-position: center top;
        background-repeat: no-repeat;
        width: 100%;
    }
}

.testimonials {
    &-section {
        background-color: $gray-100;

        &__bg {
            max-width: 1440px;
            background-image: url('../../assets/img/landing/bg/Background-3.svg');
            background-position: top center;
            background-repeat: repeat;
            min-height: 100%;
        }
    }
}

.start-section {
    background-color: $primary;
    &__bg {
        background: url('../../assets/img/landing/bg/bg-start.svg') center
            center no-repeat;
        min-height: 362px;
        width: 100%;
    }
}

.section-pricing {
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 72px;
    color: $color-landing;

    @media screen and (max-width: 639px) {
        padding-top: 40px;
    }

    &__content {
        max-width: 1140px;
        margin: 0 auto;
    }
    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 40px;
        line-height: 130%;
        padding-bottom: 50px;

        @media screen and (max-width: 639px) {
            padding-bottom: 32px;
            font-size: 24px;
        }
    }

    &__description {
        line-height: 22px;
        opacity: 0.7;
    }
}

.pricing-card {
    width: 360px;
    border: 2px solid #edeff3;
    border-radius: 16px;
    transition: transform 0.2s ease;

    &__medium {
        background: #f6f7f9;
        margin: 0 32px;

        @media screen and (max-width: 639px) {
            margin: 0;
        }
    }

    &__divider {
        margin: 0 -25px;
        height: 2px;
        width: 100wh;
        background-color: #edeff3;

        @media screen and (max-width: 768px) {
            margin: 0 -16px;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.6;
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        opacity: 0.8;

        &--font {
            font-weight: 400;
        }

        // @media screen and (max-width: 639px) {
        //     font-size: 10px;
        // }

        // @media screen and (max-width: 1023px) {
        //     font-size: 12px;
        // }
    }

    &__features {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
    }

    &__subscription {
        font-size: 20px;
        line-height: 130%;
        font-weight: 600;
        color: #001a37;

        @media screen and (max-width: 639px) {
            font-size: 16px;
        }
    }

    &__popular {
        background: rgba(0, 123, 255, 0.15);
        border-radius: 4px;
        padding: 4px 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #007bff;
    }

    &__list {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        // margin-bottom: 32px;
    }

    &__item {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 4px;

        &--status {
            text-decoration-line: line-through;
            opacity: 0.4;
        }

        // @media screen and (max-width: 639px) {
        //     font-size: 10px;
        //     margin-bottom: 8px;
        // }

        // @media screen and (max-width: 1023px) {
        //     font-size: 12px;
        //     margin-bottom: 10px;
        // }
    }

    &__icon {
        display: block;
        margin-right: 16px;

        @media screen and (max-width: 639px) {
            margin-right: 8px;
        }

        @media screen and (max-width: 1023px) {
            margin-right: 10px;
            width: 14px;
            height: 14px;
        }
    }

    &__btn {
        background: #007bff;
    }
}

.section-contact {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    margin-top: 72px;
    background-color: $color-grey;
    color: #001a37;

    @media screen and (max-width: 639px) {
        margin-top: 36px;
    }
}

.contact {
    padding: 72px 0;

    @media screen and (max-width: 639px) {
        padding: 36px 12px;
    }

    &__title {
        margin: auto;
        text-align: center;
        font-weight: 600;
        font-size: 40px;
        line-height: 130%;
        padding-bottom: 16px;

        @media screen and (max-width: 639px) {
            font-size: 24px;
        }
    }

    &__description {
        margin: auto;
        padding-bottom: 48px;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        opacity: 0.8;

        @media screen and (max-width: 639px) {
            font-size: 14px;
            padding-bottom: 32px;
        }
    }
}
