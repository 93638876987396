.dropdown-list {
    min-width: 95px;
    padding: 5px 0;
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px rgba(66, 82, 110, 0.2);
    border-radius: 8px;
    z-index: 9999;
}

.dropdown-link {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #001a37;
    background-color: #ffffff;
    width: 100%;
    text-align: left;
    &:hover {
        background-color: $color-grey;
    }
}

.dropdown-list-up {
    min-width: 95px;
    padding: 5px 0;
    position: absolute;
    bottom: calc(100% + 4px);
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px rgba(66, 82, 110, 0.2);
    border-radius: 8px;
    z-index: 9999;
}
