.heading-tab {
    background-color: #f6f7f9;
    border-radius: 10px;
}

.link-underline {
    text-decoration: underline;
}

.doc-usage-box {
    background-color: rgba(0, 123, 255, 0.05);
    padding: 16px;
    border-radius: 8px;
}
