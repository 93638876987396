.routes-nav-container {
    position: relative;
}

.routes-nav {
    position: fixed;
    top: 208px;
    border-left: 3px solid $gray-300;
    @media (min-width: 1015px) {
        top: 158px;
        max-width: 156px;
    }
}

.routes-nav-link {
    @extend %text-semi-small;
    color: rgba($black, 0.7);
}

.route-view-table {
    border-bottom: 2px solid $color-grey-hover;
    thead {
        border-bottom: 2px solid $color-grey-hover !important;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #001a37;
        opacity: 0.7;
    }
    tbody {
        font-size: 14px;
        line-height: 16px;
        color: #001a37;
    }
    tr {
        width: 100%;
        vertical-align: middle;
        height: 100%;
        border-bottom: 1px solid $gray-200 !important;
    }
    th {
        padding: 20px 16px 10px;
    }
    td {
        padding: 16px;
    }

    .opacity-1 {
        opacity: 1;
    }
}

.badge-route {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #001a37;
}

.create-route-panel {
    padding: 16px;
    margin: 0 1rem 1rem;
    border: 2px solid $color-grey-hover;
    border-radius: 8px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;
    &:hover {
        background-color: rgba(13, 110, 253, 0.08);
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.65;
        cursor: auto;
    }
    &.not-found {
        pointer-events: none;
        align-items: center;
    }
    &__title {
        color: #007bff;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 8px;
        svg {
            margin-right: 11px;
        }
    }
    &__desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #001a37;
        opacity: 0.7;
    }
}

.routes-nav-panel {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 18px;
    color: #001a37;
    .routes-nav-title {
        @extend %text-small-uppercase;
        opacity: 0.54;
        line-height: 16px;
    }
    .routes-nav-trash {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            margin-right: 8px;
        }
    }
}

.action-container {
    position: relative;
    margin-left: auto;
    min-width: 24px;
    min-height: 24px;
}

.response-example {
    .response-example-title {
        @extend %text-bold-label;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
    .response-example-desc {
        @extend %text-semi-small;
        color: #001a37;
        opacity: 0.7;
    }

    &-shared-link {
        padding: 10px;
        border: 1px solid #000;
        border-radius: 8px;
    }
}

.response-example-tabs {
    width: 100%;
    display: flex;
    padding-bottom: 10px;
}

.response-example-tabs-list {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 0px;
    grid-row-gap: 10px;

    &-item {
        background-color: #f8f9fa;

        &:hover {
            background-color: #d3d4d5 !important;
        }
    }

    &--active {
        background-color: #d3d4d5;
    }
}

.response-example-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 12px;
}

.route-tabs-list {
    border-bottom: 2px solid #edeff3;
    margin: 0;
    padding: 0;

    &-item {
        border: 2px solid transparent;
        border-bottom: none;
        cursor: pointer;
        display: inline-block;
        list-style: none;
        padding: 6px 12px;
        position: relative;
        font-size: 16px;
        font-weight: 500;
        &--selected {
            @extend .route-tabs-list-item;
            background: #fff;
            border-color: #edeff3;
            border-radius: 5px 5px 0 0;
            color: #000;

            &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;

                width: 100%;
                height: 2px;

                background-color: #fff;
            }
        }
    }
}
