.Toastify__toast-container {
    width: 420px !important;
    z-index: 99999 !important;
}

.Toastify__toast {
    background: #ffffff !important;
    color: #001a37 !important;
    padding: 15px 16px 15px 72px !important;
    box-shadow: 1px 4px 12px rgba(66, 82, 110, 0.08) !important;
    border-radius: 8px !important;
    &:before {
        content: '';
        position: absolute;
        width: 56px;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.Toastify__toast--success:before {
    background-color: #27ae60;
    background-image: url(../../assets/img/check-circle.svg);
}

.Toastify__toast--error:before {
    background-color: #fc4d70;
    background-image: url(../../assets/img/x-circle.svg);
}

.Toastify__toast--warning:before {
    background-color: #f9a620;
    background-image: url(../../assets/img/alert-circle.svg);
}

.Toastify__close-button {
    color: #001a37 !important;
    opacity: 0.37 !important;
    align-self: center !important;
}

.toastify-forgot-password {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $color-landing;
}

.toastify-forgot-password-description {
    padding-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.7;
}
