.Tooltip-Tip {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    --bs-tooltip-padding-x: 16px;
    --bs-tooltip-padding-y: 16px;
    --bs-tooltip-border: 1px solid red;
    --bs-tooltip-color: #001a37;
    --bs-tooltip-bg: white;
    --bs-tooltip-opacity: 1;

    & .tooltip-inner {
        box-shadow: 0px 15px 35px -5px rgba(17, 24, 38, 0.15),
            0px 5px 15px -3px rgba(0, 0, 0, 0.08);
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.2));
    }
    & .tooltip-arrow {
        z-index: 1;
    }
}

:root {
    --tooltip-text-color: #001a37;
    --tooltip-background-color: white;
    --tooltip-arrow-size: 10px;
}

.tooltip-wrapper {
    display: inline-block;
    position: relative;
}

.tooltip-tip.hidden {
    display: none;
}

.tooltip-tip {
    max-width: 600px;
    position: fixed;
    transform: translate(
        calc(-50% - 16px),
        calc(-100% - var(--tooltip-arrow-size))
    );
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    z-index: 9999;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 15px 35px -5px rgba(17, 24, 38, 0.15),
        0px 5px 15px -3px rgba(0, 0, 0, 0.08);
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.2));
    word-wrap: break-word;
    white-space: wrap;
}

.tooltip-tip::before {
    content: ' ';
    left: 50%;
    top: 99%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
    border-top-color: var(--tooltip-background-color);
}
