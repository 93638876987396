.react-datepicker {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    &-wrapper {
        width: auto;
    }
    &__input-container input {
        display: block;
        padding: 0.5rem 0.8rem 0.5rem 2rem;
        max-width: 183px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 2px solid #edeff3;
        appearance: none;
        border-radius: 8px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:focus {
            color: #212529;
            background-color: #fff;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
    }
    &__header {
        background-color: rgba(13, 110, 253, 0.08);
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    &__day {
        &:hover {
            background-color: $color-grey-hover;
        }
        &--selected {
            background-color: $color-primary-active;

            &:hover {
                background-color: $color-primary-light;
            }
        }
    }
    &__input-time-container {
        text-align: center;
        margin: 0 0 10px;
    }
}
