.env-editor {
    &-wrapper {
        padding: 3px 0 3px 24px;

        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: $default-border-color;
    }

    &-select {
        width: 200px;
    }

    &-button {
        margin-left: 10px;
        padding: 7px;

        border-radius: 8px;
        background-color: #f6f7f9;
    }
}

.thead-fixed {
    position: sticky;
    top: 0;
    background-color: #fff;
    box-shadow: 0 2px 2px -1px #e9ecef;
}
