.table-main {
    width: 100%;
    vertical-align: middle;
    border-collapse: collapse;
    border-spacing: 0;
    color: $black;
    border-bottom: 2px solid $color-grey-hover;
    vertical-align: middle;

    &-head {
        border-bottom: $table-head-border;
    }

    &-row {
        color: $black;
        vertical-align: middle !important;
        transition: all 0.2s ease;
        @extend %text-link-normal;
    }

    &-row:hover {
        background-color: $color-grey;
        transition: all 0.2s ease;
    }

    &-body {
        border-style: none;
        vertical-align: middle !important;
    }

    &-link {
        vertical-align: middle !important;
        color: $primary;
        @extend %text-link-normal;

        &:hover {
            text-decoration: underline;
            color: $color-primary-border;
        }

        &:active {
            text-decoration: underline;
            color: $primary;
        }

        &:focus {
            outline: none;
            text-decoration: underline;
            color: $color-primary-border;
        }
    }
}

.item-table {
    width: 100%;
    border-bottom: 2px solid $color-grey-hover !important;
}

.item-table-head {
    border-bottom: 2px solid $color-grey-hover !important;
}

.item-table-title {
    @extend %text-semi-small;
    font-weight: 400;
    color: rgba($black, 0.7);
}

.item-table-row {
    vertical-align: middle;
    @extend %text-link-normal;
    transition: all 0.2s ease;

    &:hover {
        background-color: $color-grey;
        transition: all 0.2s ease;
    }

    @media (max-width: 380px) {
        font-size: 14px;
    }
}

.pending-table-title {
    white-space: nowrap;
}

.table-scroll {
    @media (max-width: 960px) {
        overflow-x: scroll;
    }
}
