.plansContainer {
    &__subheader {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        opacity: 0.7;
    }

    .section-pricing {
      padding: 0;
    }
}
