.AppErrorFallback {
    &__container {
        max-width: 500px;
        margin: 100px auto 0;

        text-align: center;
    }

    &__title {
        margin: 10px 0;
        font-size: 30px;
    }

    &__text {
        margin-bottom: 25px;

        font-size: 18px;
    }

    &__refresh-link {
        color: $color-landing-blue;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
