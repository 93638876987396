.comment-item {
    &:hover .comment-button-group {
        opacity: 1;
    }
}

.comment-button-group {
    opacity: 0;
}

.comment-button {
    background-color: white;
    border-radius: 5px;
    padding: 6px 16px;
    font-weight: 600;
    color: #000;

    transition: background 0.5s;

    &:hover {
        transition: background 0.5s;
        background: rgba(0, 123, 255, 0.05);
    }

    &--active {
        background-color: $color-grey;
        color: #0d6efd;
    }
}

.comment-image {
    width: 32px;
    height: 32px;
    margin-top: 8px;
}
