.header-logo {
    padding-right: 16px;

    @media (min-width: 768px) {
        padding-right: 32px;
    }

    @media (min-width: 992px) {
        padding-right: 80px;
    }
}

.header-wrapper {
    box-shadow: $header-box-shadow;
    position: fixed;
    top: var(--topMessageHeight, 0px);
    left: 0;
    right: 0;
    z-index: 9999;
    border-bottom-color: $default-border-color;
    background-color: $body-bg;
}

.header-dropdown-btn {
    transition: background-color 0.2s ease;

    &:active {
        background-color: $gray-200;
        outline: none !important;
        color: $black;
    }
}

.header-dropdown-list {
    min-width: 150px;
    border: none;
    box-shadow: $custom-dropdown-box-shadow;
    border-radius: 4px;
}

.header-link {
    padding: 13.5px 16px;
    color: $black;
    @extend %text-link;

    &:focus {
        border: none !important;
    }
}

.projects-favorite {
    opacity: 0.54;
}

.sub-menu {
    @extend %text-small-uppercase;
}

.header-project-icon {
    margin-right: 16px;
}

.header-project-star {
    margin-left: auto;
    width: 14px;

    &-img {
        color: $yellow;
        fill: $yellow;
    }
}

.header-divider {
    color: $color-grey;
}

.header-projects-none {
    margin: 45px 16px 45px;

    &-icon {
        color: $black;
    }

    &-icon path {
        width: 40px;
        height: 36px;
    }

    @media (min-width: 768px) {
        margin: 60px 32px 60px;
    }
}

.header-link-text-big {
    min-width: 180px;
    @extend %text-big;
}

.header-link-text-none {
    min-width: 180px;
    @extend %text-big;
    font-weight: 400;
}

.header-search-container {
    max-width: 230px;
}

.header-searc-label {
    display: flex;
    position: relative;
}

.header-search {
    position: relative;
    padding-left: 40px !important;
    border-radius: 8px !important;
    border: 1.5px solid $gray-200;
    background-color: $color-grey;
    width: 100%;

    &:hover {
        background-color: $gray-200;
    }

    &:focus {
        background-color: $white;
        border: 1.5px solid $color-primary-border !important;
        outline: none;
        box-shadow: none;
    }
}

.header-search-icon {
    position: absolute;
    left: 16px;
    top: calc(50% - 3px);
    transform: translateY(-50%);
    color: $black;
    z-index: 1;
}

.header-round-btn {
    width: 36px;
    height: 36px;
    margin: 4px 0 4px 27px;
    border-radius: 50%;
    transition: all 0.2s ease-in;

    &:focus {
        outline: none;
    }
}

.header-notifications-btn {
    background-color: transparent;
    &:hover {
        background-color: $color-grey;
    }

    &:focus {
        background-color: $gray-200;
    }
}

.header-notifications-btn-icon {
    color: rgba($black, 0.7);
}

.header-notifications-title {
    @extend %title-h3;
}

.heder-notifications-text {
    @extend %text-semi-small;
    font-weight: 400;
    letter-spacing: 0.04em;
    opacity: 0.7;
}

.header-avatar-btn {
    &:focus,
    &:hover {
        box-shadow: 0 0 2px $black;
    }
}

.header-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @extend %text;
}

.starred-projects-container {
    max-height: 138px;
}
