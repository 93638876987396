.btn-primary {
    --bs-btn-color: #{$white};
    --bs-btn-bg: #{$primary};
    --bs-btn-border-color: #{$primary};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0b5ed7;
}

.btn-danger {
    --bs-btn-color: #{$white};
    --bs-btn-bg: #{$danger};
    --bs-btn-border-color: #{$danger};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #bb2d3b;
}

.btn-light {
    --bs-btn-color: #{$black};
    --bs-btn-bg: #{$light};
    --bs-btn-border-color: #{$light};
    --bs-btn-hover-color: #{$black};
    --bs-btn-hover-bg: #d3d4d5;
    --bs-btn-hover-border-color: #babbbc;
}

// .btn-link {
// --bs-btn-color: #{$primary};
// --bs-btn-bg: transparent;
// --bs-btn-font-weight: 400;
// --bs-btn-border-color: transparent;
// --bs-btn-hover-color: #0a58ca;
// --bs-btn-hover-border-color: transparent;
// }

.btn-outline-dark {
    --bs-btn-color: #{$dark};
    --bs-btn-bg: transparent;
    --bs-btn-border-color: #{$dark};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #{$dark};
    --bs-btn-hover-border-color: #{$dark};
}

.link-underline {
    color: $link-underline-color;
    text-decoration-color: $link-underline-td-color;

    &:hover,
    &:focus {
        color: $link-underline-hover-color;
        text-decoration-color: $link-underline-td-hover-color;
    }
}
