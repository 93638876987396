.billing--paragraph {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    opacity: 0.7;

    &-lineheight {
        line-height: 20px;
    }

    &__small {
        font-size: 12px;
        line-height: 14px;
        opacity: 0.8;
    }
}

.billing--title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.billing--card-block {
    width: 544px;
    padding: 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.billing--status {
    display: inline-block;
    background: rgba(0, 123, 255, 0.15);
    border-radius: 4px;
    padding: 4px 12px;
    margin-left: 16px;
    font-weight: 600;
    font-size: 14px;
    color: #0d6efd;
}

.billing--plan-price {
    font-weight: 600;
    font-size: 40px;
    line-height: 53px;
}

.billing--item-status {
    padding: 6px 16px;
    max-width: 74px;
    border-radius: 4px;
    background-color: $color-grey;
    text-align: center;

    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
    }

    .paid {
        span {
            color: #27ae60;
        }
    }

    .unpaid {
        span {
            color: #ff2e2e;
        }
    }
}

.billing--modal-card {
    width: 100%;
    margin-top: 8px;
    padding: 8px 16px;
    border: 1px solid #edeff3;
    border-radius: 8px;

    &-background {
        background-color: #f6f7f9;
    }
}

.billing-divider {
    margin: 24px 0;
    height: 1px;
    background-color: #edeff3;
    width: 100%;
}

.billing--payment-card {
    border: 1px solid #edeff3;
    border-radius: 8px;
    padding: 16px 16px 24px;
}

.billing--decline-link {
    color: rgb(220, 53, 69);
    cursor: pointer;
    text-decoration: underline;
}

#payment-form {
    background: #f6f7f9;
    border-radius: 8px;
    padding: 16px 16px;
    margin: 24px 0;
}

#card-element {
    border-radius: 8px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}

#payment-messages {
    display: none;
    background-color: #000;
    color: #00d924;
    padding: 20px;
    margin: 20px 0;
    font-size: 0.7em;
}

.billing--new-card {
    #payment-form {
        margin: 0 0 0 16px;
        width: 100%;
    }
}
