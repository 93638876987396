// Gilroy font
@include font-face(
    Gilroy,
    '../assets/fonts/Gilroy/Gilroy-Regular',
    normal,
    normal,
    eot woff ttf
);
@include font-face(
    Gilroy,
    '../assets/fonts/Gilroy/Gilroy-Medium',
    500,
    normal,
    eot woff ttf
);
@include font-face(
    Gilroy,
    '../assets/fonts/Gilroy/Gilroy-SemiBold',
    600,
    normal,
    eot woff ttf
);

:root {
    --topNavigationHeight: 60px;
    --topMessageHeight: 0px;
    --contentTop: var(--topNavigationHeight, 0px);
    --leftSidebarWidth: 0px;
    --leftPanelWidth: 0px;
}

body {
    font-family: $body-font, sans-serif;
    font-weight: $body-font-weight;
    font-size: $body-font-size;
    line-height: $body-line-height;
    color: $body-color;
    background-color: $body-bg;
}

a.dropdown-link:hover {
    color: inherit;
}

.title-h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 140%;
}

.title-h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: $black;
}

%title-h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: $black;
}

.title-h3-medium {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: rgba($black, 0.7);
}

.title-h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.title-h5 {
    @extend %text-bold-label;
}

.text-small {
    @extend %text-small-uppercase;
}

.text-semi-small {
    @extend %text-semi-small;
}

.text-semi-small-normal {
    @extend %text-semi-small-normal;
}

.text-big {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $black;
}

%text-big {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $black;
}

%text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
}

%text-btn-main {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $white;
}

%text-semi-small {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

%text-semi-small-normal {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

%text-small {
    font-size: 12px;
    line-height: 14px;
}

.title-table {
    @extend %text-small;
    font-weight: 600;
    color: rgba($black, 0.7);
}

%text-small-uppercase {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}

%text-link {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}

%text-link-normal {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}

%text-bold-label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.text-link-normal {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.bg-gray {
    background-color: #f5f6f8;
}

.master-container {
    position: relative;
    padding-top: calc(var(--contentTop, 0px) + var(--topMessageHeight, 0px));
}

.main-container {
    // max-width: 1440px;
    margin: 0 auto;
    margin-bottom: 60px;
}

.page-header {
    margin-top: 24px;
    margin-bottom: 54px;

    &__back {
        font-size: 14px;
        color: #000;
        padding-left: 14px;
        line-height: 16px;
        opacity: 0.7;
    }
}

.main-layout {
    height: 100%;
    position: relative;
}

.main-content {
    min-width: 0;
    -webkit-transition: margin-left 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    transition: margin-left 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    margin-left: 0;
    width: calc(
        100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) -
            var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px)
    ) !important;

    > :first-of-type {
        transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
}

.form-group-main {
    position: relative;
}

.mb-24 {
    margin-bottom: 24px;
}

.word-break {
    word-break: break-all;
    &--word {
        word-break: break-word;
    }
}

.anchor {
    display: block;
    position: relative;
    top: calc(var(--topNavigationHeight) * -1 - 10px);
    visibility: hidden;
}

.cursor-pointer {
    cursor: pointer;
}

.big-dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #001a37;
}

.icon-sticky {
    position: sticky;
    top: calc(var(--topNavigationHeight) + 10px);
}

.nowrap {
    white-space: nowrap;
}

.border-radius-8 {
    border-radius: 8px;
}

.user-select-none {
    user-select: none;
}

.recent-data {
    &__path {
        color: #00000082;
    }

    &__date {
        color: #000000;
        font-size: 13px;
    }

    &__created {
        color: #27ae60;
    }

    &__updated {
        color: #f9a620;
    }

    &__deleted {
        color: #fc4d70;
    }
}
