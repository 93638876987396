.project-list {
    color: $black;
}

.project-logo {
    border-radius: $border-radius;
}

.project-member-logo {
    border-radius: 50%;
}

.projects-table {
    border-bottom: 2px solid $color-grey-hover;

    &-item {
        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-hover !important;
        }
    }

    &-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: rgba($black, 0.7);
        border-bottom: 2px solid $color-grey-hover;
    }

    // &-star {
    //     color: $black;
    //     stroke: $black;
    //     fill: $black;
    //     opacity: .7;
    // }

    &-row:hover .project-more-img {
        transition: all 0.2s ease;
        background-color: $color-grey-hover;
    }

    &-dots {
        display: block;
        height: 32px;
        width: 32px;
        border-radius: calc($border-radius / 2);
        transition: all 0.2s ease;

        &:hover .project-more-img {
            background-color: $color-grey-hover;
        }

        &:focus {
            border: none;
            outline: none;
        }

        &:focus .project-more-img {
            background-color: $black;
            color: $white;
        }

        &:active .project-more-img {
            background-color: rgba($primary, 0.15);
            color: $primary;
        }
    }
}

.project-more-img {
    border-radius: calc($border-radius / 2);
    color: $black;
    padding: 4px;
    width: 32px;
    height: 32px;
    background-color: $white;
}

.btn-star {
    background-color: transparent;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.2s ease;

    &:hover .btn-star-img,
    &:focus .btn-star-img {
        color: rgba($black, 0.7);
    }

    &:active .btn-star-img {
        background-color: $color-yellow;
        color: $white;
        fill: $white;
    }

    &:focus {
        outline: none !important;
    }

    .btn-star-img-selected {
        border: none;
        color: rgba($color-yellow, 1);
        fill: $color-yellow;
    }
}

.btn-star-img {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    color: rgba($black, 0.1);
}

// .btn-star-icon {
//     border-radius: 50%;
//     width: 16px;
//     height: 16px;
//     color: rgba($black, .1);
//     background: url("../../../assets/img/stars/Star.svg") center / 20px 20px no-repeat;
//     // background: url('/img/svg/menu.svg') center / 20px 20px no-repeat;
//     // C:\workprojects\app.codedinn.io\src\assets\img\stars\Star.svg
// }

.btn-star-icon {
    background-color: transparent;
    width: 16px;
    height: 16px;
    background-image: url('../../assets/img/stars/star-grey.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.2s ease;

    &:hover {
        background-image: url('../../assets/img/stars/star-grey-bold-border.svg');
    }

    // &:active {
    //     background-image: url('../../assets/img/stars/star-table-active.svg');
    // }

    // &:focus {
    //     border: none;
    //     outline: none;
    //     // background-image: url('../../../assets/img/stars/star-grey-bold-border.svg');
    //     background-image: url('../../assets/img/stars/star-table-active.svg');
    //     // background-image: url('../../../assets/img/stars/star-yellow.svg');
    // }
}

.btn-star-selected {
    background-image: url('../../assets/img/stars/star-yellow.svg');
}

.project-name {
    padding-left: 16px;
}

.project-dropdown-link {
    padding: 12px 16px;
    font-weight: 400;
}

.project-deleted-tag {
    margin-left: 16px;
    padding: 4px 8px;
    position: relative;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    color: rgba($black, 0.7);
    background-color: rgba($gray-300, 0.4);
}

.project-disabled {
    opacity: 0.8;
}
