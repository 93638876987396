.inline-logo {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @extend %text;

    &--large {
        width: 56px;
        height: 56px;
    }
}

.project-logo {
    border-radius: $border-radius;
}

.project-member-logo {
    border-radius: 50%;
}

.avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    position: relative;
    padding: 1px;
    height: 134px;
    width: 134px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    margin-bottom: 17px;

    font-size: 32px;

    span {
        display: flex;
        flex: 1 1 100%;
        height: 100%;
        width: 100%;
    }
}
