@import 'Landing';
@import 'Pricing';
@import 'Contact';
@import 'SignUp';
@import 'Login';
@import 'Routes';
@import 'Documentation';
@import 'Partners';
@import 'Billing';
@import 'Plans';

.form-bg-image {
    background: url('../../assets/img/landing/bg/bg-signup.svg') top center
        no-repeat;
    min-height: 796px;
    width: 100%;
}
