// .css-1b07wpr-control {
//     border: $control-border !important;
//     border-radius: $control-border-radius !important;
//     height: 44px;
// }

.select-main-container {
    min-width: 60px;
    transition: all 0.2s ease;
    margin-right: 2px;
}

.input-group {
    .select-main__control {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    &-append {
        min-width: 110px !important;
    }
}

.css-lp0il6 + div {
    width: 100%;
}

.select-main__menu-portal {
    min-width: 120px !important;
    z-index: 10001 !important;
}

.w-20 {
    width: 20%;
}
