.link-dropdown {
    position: relative;
    color: $dropdown-link-color;
    transition: background-color 0.2s ease;
    white-space: initial;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
    }

    &:active {
        color: $black;
        background-color: $dropdown-link-active-bg;
    }
}

.tab-link-main {
    position: relative;
    color: rgba($black, 0.54);
    white-space: nowrap;
    @extend %text-link-normal;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: $primary;
        border-color: $primary;
        border-radius: 4px 4px 0px 0px;
        transform: scale(0);
        transition: transform 0.2s ease;
    }

    &--active {
        color: $primary;

        &::before {
            transform: scale(1);
        }
    }

    &.disabled {
        opacity: 0.32;
    }
}

.nav-tab-link {
    position: relative;
    opacity: 0.7;
    transition: all 0.2s ease;
    @extend %text;

    &::before {
        content: '';
        position: absolute;
        height: 1.95px;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: $primary;
        transform: scaleX(0);
        transition: all 0.2s ease;
    }
}

.nav-tab-link.active {
    color: $primary;
    opacity: 1;

    &::before {
        transform: scaleX(1);
    }
}

.link-back {
    color: $black;
    display: inline-flex;
    align-items: center;
    margin-bottom: 28px;
    &:hover {
        svg {
            stroke: #0a58ca;
        }
    }
    @extend %text-semi-small;
}
