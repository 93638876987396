.section-partners {
    max-width: 1440px;
    margin: 0 auto;

    &__container {
        max-width: 1140px;
        display: grid;
        place-items: center;
        padding-top: 72px;
        color: $color-landing;

        @media screen and (max-width: 639px) {
            padding-top: 40px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 56px;
        line-height: 130%;
        padding-bottom: 48px;

        @media screen and (max-width: 639px) {
            padding-bottom: 24px;
            font-size: 32px;
        }
    }

    &__description {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 24px;

        @media screen and (max-width: 639px) {
            padding-bottom: 16px;
            font-size: 20px;
        }
    }

    &__text {
        max-width: 730px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        @media screen and (max-width: 639px) {
            font-size: 12px;
            line-height: 20px;
        }
    }
}
