.welcome-header {
    padding: 0 32px;
    margin: 0 auto;
    max-width: 1440px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    background-color: #fff;
    z-index: 50;

    @media screen and (max-width: 1025px) {
        padding: 12px;
    }

    &__nav {
        display: none;
        padding: 30px 0;

        @media screen and (min-width: 1025px) {
            display: block;
        }
    }

    &__link {
        color: $color-landing;
        opacity: 0.8;
        transition: color 0.5s;

        &:hover {
            color: $color-landing-blue;
            transition: color 0.5s;
        }
    }

    &__buttons {
        display: none;

        @media screen and (min-width: 1025px) {
            display: block;
        }
    }

    &__login {
        padding-right: 32px;
        font-weight: 500;
        color: $color-landing-blue;
    }

    &__signup {
        background-color: $color-landing-blue;
        text-align: center;
    }
}

.welcome-footer {
    max-width: 1440px;
    padding: 0 32px;
    padding-top: 72px;
    margin: 0 auto;
    color: $color-landing;
    z-index: 100;

    @media screen and (max-width: 639px) {
        padding-top: 40px;
    }

    @media screen and (max-width: 1025px) {
        padding-right: 12px;
        padding-left: 12px;
    }

    &__container {
        margin: 0 auto;
    }

    &__content {
        border-bottom: 1px solid #edeff3;
        flex-wrap: nowrap;

        @media screen and (max-width: 639px) {
            flex-wrap: wrap;
        }
    }

    &__title {
        padding-bottom: 16px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        @media screen and (max-width: 639px) {
            font-size: 14px;
            line-height: 19px;
        }
    }

    &__item {
        padding-bottom: 16px;

        &--padding {
            padding-bottom: 96px;

            @media screen and (max-width: 639px) {
                padding-bottom: 40px;
            }
        }
    }

    &__link {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $color-landing;
        transition: color 0.3s;

        &:hover {
            transition: color 0.3s;
            color: $color-landing-blue;
        }

        @media screen and (max-width: 639px) {
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__copyright {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 40px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        opacity: 0.54px;

        @media screen and (max-width: 639px) {
            font-size: 12px;
            padding: 20px 0;
        }
    }

    &__logo {
        @media screen and (max-width: 639px) {
            width: 100%;
            padding-bottom: 16px;
        }
    }
}

.welcome-common-form {
    width: 100wh;
    margin: 0 auto;
    background: #f6f7f9;
    overflow-x: hidden;

    &__container {
        max-width: 1440px;
        position: relative;
        z-index: 10;
    }

    &__content {
        width: 100%;
        padding: 72px 0;
        color: $color-landing;
        z-index: 2;
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;

        @media screen and (max-width: 639px) {
            font-size: 20px;
            line-height: 25px;
        }
        &--color {
            color: $color-landing-blue;
        }

        &--padding-b {
            padding-bottom: 32px;
        }
    }

    &__form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        max-width: 554px;
        margin: 0 auto;
        padding: 32px;
        box-shadow: 1px 4px 12px rgba(66, 82, 110, 0.08);
        border-radius: 16px;
    }

    &__google {
        display: grid;
        place-items: center;
        padding: 36px 0 40px;

        @media screen and (max-width: 639px) {
            padding: 20px 0;
        }
    }

    &__with-google {
        transition: background-color 0.3s, box-shadow 0.3s;
        background-color: #f6f7f9;
        display: flex;
        width: 100%;
        border: 1px solid #edeff3;
        border-radius: 8px;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
        color: $color-landing;
        font-weight: 600;
        font-size: 16px;

        &:hover {
            box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04),
                0 2px 4px rgba(0, 0, 0, 0.25);
        }

        &:active {
            background-color: #eeeeee;
        }

        &:focus {
            outline: none;
            box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04),
                0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
        }
    }

    &__google-image {
        display: inline;
        padding-right: 12px;
    }

    &__divider {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-bottom: 32px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        opacity: 0.54;

        &:after {
            height: 1px;
            content: '';
            width: 100%;
            background-color: #edeff3;
            margin-left: 24px;
        }

        &:before {
            height: 1px;
            content: '';
            width: 100%;
            background-color: #edeff3;
            margin-right: 24px;
        }

        @media screen and (max-width: 639px) {
            padding-bottom: 16px;
        }
    }

    &__form-group {
        padding-bottom: 32px;

        &--padding-top {
            padding-top: 32px;

            @media screen and (max-width: 639px) {
                padding-top: 16px;
            }
        }

        @media screen and (max-width: 639px) {
            padding-bottom: 16px;
        }
    }

    &__control {
        background-color: #f6f7f9;
        font-weight: 400;
        font-size: 16px;
        color: $color-landing;
    }

    &__label {
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color-landing;
    }

    &__check {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        margin-left: 16px;

        @media screen and (max-width: 639px) {
            font-size: 14px;
        }
    }

    &__link {
        font-weight: 500;
        line-height: 140%;
        color: $color-landing-blue;

        @media screen and (max-width: 639px) {
            font-size: 14px;
        }
    }

    &__toolbar {
        padding: 32px 0;
        width: 100%;

        @media screen and (max-width: 639px) {
            padding: 24px 0;
        }
    }

    &__button {
        width: 100%;
        background-color: $color-landing-blue;
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        line-height: 19px;
    }

    &__footer-link {
        padding-left: 16px;
        font-weight: 500;
        color: $color-landing-blue;
    }

    &__bg {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 20%;
        left: 45%;
        content: '';
        z-index: -1;
        background: url('../../assets/img/landing/Pattern-form.png') no-repeat;
        object-fit: cover;

        @media screen and (max-width: 639px) {
            display: none;
        }
    }
}

.welcome-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transform: translateX(-160%);
    transition: all 0.5s;
    z-index: 999;
}

.welcome-menu-active {
    transform: translateX(0);
}

.welcome-menu__btn {
    display: none;
    width: 24px;
    height: 24px;

    @media screen and (max-width: 1024px) {
        display: block;
    }
}

.welcome-blur {
    width: 100vw;
    height: 100vh;
    left: 50%;
    backdrop-filter: blur(2px);
    position: absolute;
}

.welcome-menu__box {
    display: block;
    position: fixed;
    top: 0;
    width: 60%;
    height: 100%;
    margin: 0;
    padding: 12px;
    list-style: none;
    background-color: $color-grey;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
    transition-duration: 0.25s;
    transition: all 0.5s;
}

.welcome-menu-close {
    position: absolute;
    top: 0;
    right: 24px;
    cursor: pointer;
    border-radius: 60%;
}

.welcome-menu-close:before,
.welcome-menu-close:after {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background: $color-landing;

    @media screen and (max-width: 639px) {
        width: 16px;
    }
}

.welcome-menu-close:before {
    transform: rotate(45deg);
    transform: rotate(45deg);
}

.welcome-menu-close:after {
    transform: rotate(-45deg);
    transform: rotate(-45deg);
}
