.cookie-banner {
    &__wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 0, 0, 0.726);
        z-index: 10001;
    }

    &__container {
        display: flex;
        align-items: center;
    }

    &__text {
        margin-right: 20px;
        font-size: 17px;
        color: white;
    }
}
