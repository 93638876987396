.multiselect-btn {
    padding: 6px 16px;
    background-color: $color-grey;
    min-width: 220px;
}

.multiselect-filter-list {
    width: 250px;
    margin-top: 5px !important;
}

.multiselect-filter-item {
    padding: 12px 0 12px 35px;
}

.multiselect-filter-tags {
    margin-right: 16px;
    padding-right: 32px;
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    color: rgba($black, 1);
    background-color: rgba($blue-100, 0.15);
    transition: all 0.2s ease;
}
